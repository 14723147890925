export class OrdenModel {
	cliente: string; 
	comisionServicio: number; 
	costoServicio: number; 
	detalle: string; 
	estadoOrden: string; 
	fechaEjecutada: string;
	fechaOrden: string; 
	fechaProgramada: string; 
	metodoPago: string; 
	moneda: string; 
	proveedor: string; 
	servicio: string; 
	tipoServicio: string; 
	vehiculo: string; 
	key: string;

	constructor(){
	}
}
